<template>
  <div id="rate-charts">
    <Navbar class="dark-menu-icon" />

    <section>
      <h1 class="page-title" v-if="charts.length">RATE CHARTS</h1>

      <div class="charts" v-if="charts.length">
        <div class="chart" v-for="chart in charts" :key="chart.id">
          <div class="category" :class="categoryClass(chart)">
            {{ chart.category }}
          </div>
          <h2>{{ chart.name }}</h2>
          <p>{{ chart.short_description }}</p>
          <div class="image">
            <a :href="imgUrl(chart)" target="_blank">
              <HfaImage :name="chart.id" path="assets/rate-charts" />
            </a>
            <a class="pdf" :href="chart.pdf" target="_blank">PDF Download</a>
          </div>
        </div>

        <div class="chart">
          <div class="category"></div>
          <h2>Archived Rates:</h2>
          <div class="image">
            <a
              href="http://harryfox.com/content/HFA_archived_rates_2024.pdf"
              target="_blank"
            >
              <HfaImage name="archived_rates" path="assets/rate-charts" />
            </a>
            <a
              class="pdf"
              href="http://harryfox.com/content/HFA_archived_rates_2024.pdf"
              target="_blank"
              >PDF Download</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HfaImage from "@/components/HfaImage.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  name: "RateCharts",
  components: {
    Navbar,
    HfaImage
  },
  metaInfo: function() {
    return {
      title: " - Rate Charts",
      meta: [
        {
          name: "description",
          content: "Harry Fox rate charts."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  methods: {
    imgUrl: function(chart) {
      return require(`@/assets/rate-charts/${chart.id}.jpg`);
    },
    categoryClass: function(chart) {
      switch (chart.category) {
        case "Physical":
          return "physical";
        case "Downloads":
          return "downloads";
        case "Subscription Services":
          return "subscription";
        case "Other":
          return "other";
      }
    }
  },
  computed: {
    charts: function() {
      return this.$store.state.ratecharts.charts.filter(i => !i.archived);
    },
    archived: function() {
      return this.$store.state.ratecharts.charts.filter(i => i.archived);
    }
  }
};
</script>

<style lang="scss">
// font-family: "Fjalla One", sans-serif;
// font-family: "Antic Slab", serif;
// font-family: "Abel", sans-serif;

#rate-charts {
  .charts {
    // display: flex;
    // flex-wrap: wrap;
    // flex-direction: column;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;

    @media (min-width: $desktop) {
      grid-template-columns: repeat(4, 1fr);
      margin-right: 50px;
    }

    .chart {
      position: relative;
      background-color: #fff;
      box-shadow: 0 22px 22px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      // margin-bottom: 20px;
      padding-bottom: 140px;

      .category {
        font-family: "Antic Slab", serif;
        font-size: 13px;
        margin-bottom: 5px;
        margin-top: 15px;

        &.physical {
          color: #ee70a7;
        }
        &.downloads {
          color: #ffc03f;
        }
        &.subscription {
          color: #168dff;
        }
        &.other {
          color: rgba(102, 51, 153, 0.55);
        }
      }
      h2 {
        margin: 0;
        font-family: "Fjalla One", sans-serif;
        font-size: 1em;
      }
      p {
        font-size: 15px;
        line-height: 1.4;
      }
      .category,
      h2,
      p {
        margin-left: 15px;
        margin-right: 15px;
      }
      .image {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 15px;

        img {
          height: 95px;
          display: block;
          margin: 0 auto 5px;
        }
        .pdf {
          display: block;
          text-align: center;
          text-decoration: underline;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
